<template>
  <v-dialog v-model="dialog"  max-width="500">
    <v-card>
      <v-card-title class="pb-7" > 
        <div class="headline font-weight-bold font--text">Create New Bucket</div>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-bold">Bucket Name</p>
        <v-text-field
          v-model="name"
          outlined
          placeholder="Bucket Name"
          :error-messages="$v.name.$errors.length ? $v.name.$errors[0].$message : ''"
        />
      </v-card-text>
      <!-- <v-card-text>
        <p class="font-weight-bold">Select Region</p>
        <v-select
          v-model="selectedRegion"
          :items="listRegion" 
          outlined
          autocomplete="off"
          label="Select Region" 
          clearable
          :error-messages="$v.selectedRegion.$errors.length ? $v.selectedRegion.$errors[0].$message : ''"
          item-text="region"

        >
        </v-select>
      </v-card-text> -->
       <v-card-text>
        <p class="font-weight-bold">Region</p>
        <v-text-field
          v-model="selectedRegion"
          outlined
          placeholder="Region"
          readonly
          :error-messages="$v.selectedRegion.$errors.length ? $v.selectedRegion.$errors[0].$message : ''"
        />
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn depressed
              height="50"
              color="accent"
              block
              @click="resetValue"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn depressed
              height="50"
              color="secondary"
              block
              @click="create"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { useObjectStorage } from './composableapi/useobjectstorage'
import { ref, watch,onMounted } from '@vue/composition-api'
import useVuelidate from '@vuelidate/core';
import { required, minValue,  } from '@vuelidate/validators'


export default {
  props: ['value', 'objectStorageID'],
  setup(props, context){
    const Composable = {
      ...useObjectStorage()
    }

    const {
      createBucket,
      fetchUsage,
      fetchObjectStorage,
      fetchBuckets,
    } = Composable


    const objectStorageData = ref(null) // Define ref for objectStorageData
    const objectStorageUsage=ref(null)
    const listRegion = ref([]) // Define ref for listRegion
    const selectedRegion=ref('')


    onMounted(async () => {
    if (props.objectStorageID) {
        objectStorageData.value = await fetchObjectStorage({ id: props.objectStorageID });
        
        if (objectStorageData.value) {
            const defaultRegion = objectStorageData.value.data.data.default_region;
            selectedRegion.value = defaultRegion !== "" ? defaultRegion : await fetchAndSetRegion();
        }
    }
    })

    async function fetchAndSetRegion() {
        objectStorageUsage.value = await fetchUsage({ id: props.objectStorageID });
        
        if (objectStorageUsage.value) {
            const buckets = objectStorageUsage.value.data.data.data.buckets;
            return buckets.length > 0 ? buckets[0].region : null;
        }
    }



    
    const name = ref('')
  
    const $v = useVuelidate(
    {
      name: { 
        required,
        $autoDirty: true,
        conditional: {
          $message: "The special character or spaces are not allowed",
          $validator: (val) => {
          return /^[A-Za-z0-9\-_]+$/.test(val); // Hanya menerima huruf, angka, -, dan _
          },
        },
      },
      selectedRegion: { required },
      // description: { required },
    },
    {
      name,
      selectedRegion,
      // description
    })


   

    // watch(searchUser, val => {
    //   fetchMembers({ search: searchUser.value })
    // })

    const create = async () => {
      $v.value.$touch();
      if ($v.value.$errors.length) {
          let container = document.getElementById("container");
          container.scrollIntoView({behavior: "smooth"})
          return;
      }
      const payload = {
        region: selectedRegion.value,
        name: name.value,
        object_storage_id: props.objectStorageID,
      }

      console.log(payload);

      const response = await createBucket(payload)
      console.log("response ", response);
      if(response.status == 200) {
        context.emit('close')
        context.emit('refresh')
        
        name.value = ''
       
      }
      $v.value.$reset();

    }
   
    const resetValue = async ()=>{
      name.value = ''
      context.emit('close')
    }


    return {
      ...Composable,
      name,
      create,
      objectStorageData,
      selectedRegion,
      listRegion,
      resetValue,
      fetchUsage,
      $v,
    }
  },
  
  computed:{
    dialog:{
      get:function(){
        return this.value
      },
      set:function(val){
        this.$emit('close',val)
      }
    }
  }
};
</script>
<style scoped lang="scss">
button{
  ::v-deep .v-btn__content{
  font-size: 14px;
}
}

</style>