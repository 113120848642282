var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Increase Storage Size")])]),_c('v-card-text',[(_vm.objectStorage.package_object_storage)?_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Select size for your volume. Must be greater than "+_vm._s(_vm.objectStorage.package_object_storage.object_storage_size)+". This change is not reversible. ")]):_vm._e()]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',[_c('span',{staticClass:"font-weight-bold mb-0"},[_vm._v("Select Volume Size")]),(_vm.$v.customSize.$errors.length)?_c('span',{staticClass:"ml-2 error--text",staticStyle:{"font-size":"10px"}},[_vm._v(" *"+_vm._s(_vm.$v.customSize.$errors[0].$message))]):_vm._e()])]),_c('v-slide-group',{staticClass:"py-2 pt-0 ma-0",attrs:{"active-class":"success","show-arrows":""}},[_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
                'highlight-error': _vm.$v.customSize.$errors.length,
                'hightlighted selected': _vm.$v.customSize.$errors.length < 1 && _vm.isCustomSizeHighlighted,
              },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""}},[(_vm.s3DefaultPrice)?_c('v-card-text',{staticClass:"pb-0 pt-4 d-flex flex-column m-0"},[(_vm.objectStorageDiscount && _vm.objectStorageDiscount > 0 && _vm.customSize)?_c('div',[_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage()))+"/mo")]),_c('p',{staticClass:"font-weight-bold blue--text mb-1"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage(_vm.objectStorageDiscount)))+"/mo")]),_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage(undefined, "hour")))+"/hour ")]),_c('p',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage(_vm.objectStorageDiscount, "hour")))+"/hour")])]):_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage()))+"/mo")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateCustomObjectStorage(undefined, "hour")))+"/hour")])])]):_vm._e(),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2",staticStyle:{"width":"170px"},attrs:{"max":"100","flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return (_vm.customSize = _vm.addGBText(ev, _vm.customSize)); },"click":function () {
                      _vm.volumeSelected = null;
                      _vm.isCustomSizeHighlighted = true;
                    },"blur":function () { return (_vm.isCustomSizeHighlighted = !_vm.customSize ? false : true); }},model:{value:(_vm.customSize),callback:function ($$v) {_vm.customSize=$$v},expression:"customSize"}})],1)],1),_vm._l((_vm.listFlavorObjectStorage.filter(function (row) {
                return row.object_storage_size > _vm.objectStorage.package_object_storage.object_storage_size;
              })),function(objectStorageCard){return _c('div',{key:objectStorageCard.id},[(objectStorageCard)?_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
                  highlight: _vm.volumeSelected ? _vm.is(_vm.volumeSelected.id, objectStorageCard.id) : false,
                  'highlight-error': _vm.$v.volumeSelected.$errors.length,
                },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                    _vm.volumeSelected = objectStorageCard;
                    _vm.customSize = null;
                    _vm.isCustomSizeHighlighted = false;
                  }}},[_c('v-card-text',{staticClass:"pb-0 pt-4 px-2"},[(_vm.objectStorageDiscount && _vm.objectStorageDiscount > 0)?_c('div',[_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_month)))+"/mo ")]),_c('p',{staticClass:"font-weight-bold blue--text mb-1"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_month, _vm.objectStorageDiscount)))+"/mo ")]),_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_hour, undefined)))+"/hour ")]),_c('p',{staticClass:"font-weight-bold blue--text"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_hour, _vm.objectStorageDiscount)))+"/hour ")])]):_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_month)))+"/mo")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateObjectStorage(objectStorageCard.price_per_hour)))+"/hour")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"flavor-card"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(objectStorageCard.object_storage_size)+" GB")])])],1):_vm._e()],1)})],2)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"accent","block":""},on:{"click":function () {
                _vm.customSize = null;
                _vm.isCustomSizeHighlighted = false;
                _vm.volumeSelected = false;
                _vm.$v.$reset();
                _vm.$emit('close');
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"secondary","block":"","disabled":_vm.isLoading},on:{"click":function () {
                _vm.user.role.toLowerCase() !== 'superadmin' ?
                _vm.displayObjStorageDialog() :
                _vm.resizeVolume(_vm.objectStorage);
              }}},[(_vm.isLoading)?_c('div',[_c('beat-loader',{attrs:{"loading":_vm.isLoading,"color":"white","size":"10px"}})],1):_c('span',[_vm._v(" Resize Volume ")])])],1)],1)],1)],1),(_vm.objStorageDialog)?_c('dialogNewServiceAvailability',{attrs:{"onClose":function () {_vm.objStorageDialog = false},"serviceName":"Object Storage","actionName":"Resize Object Storage","onAvailable":function () { return _vm.resizeVolume(_vm.objectStorage); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }