var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"headline mb-2 font-weight-bold font--text"},[_vm._v(" Deka Box ")]),_c('p',{staticStyle:{"color":"#a5b3bf"}},[_vm._v(" each bucket is for you to store and serve files. ")])]),(!_vm.read_only)?_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[(_vm.objectStorages.length > 0)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","append":"","to":"create","width":"150","height":"45"}},[_vm._v(" Create Deka Box ")]):_vm._e()],1):_vm._e()],1),(_vm.isLoading || (!_vm.isLoading && _vm.objectStorages.length > 0))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.objectStorages,"items-per-page":10},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? "Loading.." : "No data available")+" ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-circle")]),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_c('p')],1)],1)]}},{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [(item.billing_type == 'Trial' && item.trial_voucher_expired)?_c('span',{staticClass:"v-badge v-badge--inline theme--light"},[_vm._v(" "+_vm._s(item.billing_type)+" "),_c('span',{staticClass:"v-badge__wrapper"},[_c('span',{staticClass:"v-badge__badge pink",attrs:{"aria-atomic":"true","aria-label":"Badge","aria-live":"polite","role":"status"}},[_vm._v(" Expired ")])])]):(item.billing_type)?_c('span',[_vm._v(" "+_vm._s(_vm.getBillingTypeLabel(item.billing_type))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.is_deleting)?_c('v-progress-linear',{attrs:{"color":"blue","height":"25","striped":"","rounded":"","indeterminate":""}},[_vm._v(" Deleting ")]):_c('span',{},[_vm._v("Active")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.package_object_storage && item.package_object_storage.object_storage_size ? ((item.package_object_storage.object_storage_size) + " GB") : "N/A")+" ")])]}},{key:"item.createddate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            _vm.show($event, item);
                            _vm.selectedObjectStorage = item;
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate: item.open,
                          },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disabledField || item.is_deleting,"to":_vm.selectedObjectStorage.id + '/overview',"append":""}},[_c('v-list-item-title',[_vm._v("Show Details")])],1),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || item.is_deleting,"to":_vm.selectedObjectStorage.id + '/access-keys',"append":""}},[_c('v-list-item-title',[_vm._v("Manage Access Keys")])],1):_vm._e(),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || item.is_deleting},on:{"click":function () {
                                _vm.dialogIncreaseVol = true;
                              }}},[_c('v-list-item-title',[_vm._v("Increase Size")])],1):_vm._e(),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || item.is_deleting,"to":_vm.selectedObjectStorage.id + '/settings',"append":""}},[_c('v-list-item-title',[_vm._v("Settings")])],1):_vm._e(),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || item.is_deleting},on:{"click":function () {
                            _vm.validateprivilages([
                              'Object Storage',
                              'editor' ]).then(function () {
                              _vm.openDialogDestroy = true;
                              _vm.objectStorageToDelete = item;
                            });
                          }}},[_c('v-list-item-title',{class:{'error--text': !_vm.disabledField || !item.is_deleting,'secondary--text':_vm.disabledField || item.is_deleting}},[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},(_vm.objectStorages.length > 5)?{key:"footer",fn:function(ref){
                          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don't have any Deka Box ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Create unlimited object storage service that lets you store and serve large amounts of data. Each bucket is for you to store and serve files. The free, improves performance, and reduces bandwidth and infrastructure costs. ")])])],1),_c('v-row',[(!_vm.read_only)?_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/object-storage/create"}},[_vm._v(" Create Deka Box ")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"position-x":_vm.x - 10,"position-y":_vm.y,"absolute":"","allow-overflow":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}}),_c('dialogDestroyObjectStorage',{on:{"close":function () {
                _vm.openDialogDestroy = false;
              },"confirm":_vm.deleteS3},model:{value:(_vm.openDialogDestroy),callback:function ($$v) {_vm.openDialogDestroy=$$v},expression:"openDialogDestroy"}}),_c('dialogMovetoProject',{on:{"close":function () {
                _vm.dialogMoveto = false;
              }},model:{value:(_vm.dialogMoveto),callback:function ($$v) {_vm.dialogMoveto=$$v},expression:"dialogMoveto"}}),(_vm.dialogIncreaseVol)?_c('dialogIncreaseVolume',{attrs:{"objectStorage":_vm.selectedObjectStorage},on:{"close":function () {
                _vm.dialogIncreaseVol = false;
              }},model:{value:(_vm.dialogIncreaseVol),callback:function ($$v) {_vm.dialogIncreaseVol=$$v},expression:"dialogIncreaseVol"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }